import React from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const QuizSelection: React.FC = () => {
    const navigate = useNavigate();

    const handleQuizStart = (type: string) => {
        navigate('/quiz', { state: { type } });
    };

    return (
        <Container maxWidth="sm">
            <Box sx={{ mt: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography component="h1" variant="h4" gutterBottom>
                    选择测验类型
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleQuizStart('complete')}
                    sx={{ mt: 2 }}
                    fullWidth
                >
                    完整测验
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleQuizStart('quick')}
                    sx={{ mt: 2 }}
                    fullWidth
                >
                    快速测验
                </Button>
            </Box>
        </Container>
    );
};

export default QuizSelection;
