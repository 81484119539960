import React, { useState, useEffect } from 'react';
import Confetti from 'react-confetti';
import {
    Container, Typography, Box, RadioGroup, FormControlLabel, Radio,
    Button, CircularProgress, Grid, Snackbar, Paper, Dialog, DialogTitle,
    DialogContent, DialogActions, List, ListItem, ListItemText, Chip,
    Checkbox
} from '@mui/material';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../utils/api';
import AnswerCard from './AnswerCard';

interface Question {
    id: number;
    type: string;
    content: string;
    answer: string;
    option_a: string;
    option_b: string;
    option_c: string | null;
    option_d: string | null;
}

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Quiz: React.FC = () => {
    const [questions, setQuestions] = useState<Question[]>([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [answers, setAnswers] = useState<{[key: number]: string}>({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'error' | 'warning' | 'info' | 'success'>('info');
    const [wrongAnswers, setWrongAnswers] = useState<Question[]>([]);
    const [showWrongAnswers, setShowWrongAnswers] = useState(false);
    const [allCorrect, setAllCorrect] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const quizType = location.state?.type || 'complete';

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const response = await api.get(`/questions/${quizType}`);
                if (response.data && response.data.length > 0) {
                    setQuestions(response.data);
                } else {
                    setError('没有找到题目');
                }
            } catch (error) {
                console.error('获取题目失败:', error);
                setError('获取题目失败');
            } finally {
                setLoading(false);
            }
        };
        fetchQuestions();
    }, [quizType]);

    const getQuestionTypeLabel = (type: string) => {
        switch (type) {
            case 'multiple_choice':
                return '多选题';
            case 'true_false':
                return '判断题';
            case 'single_choice':
                return '单选题';
            default:
                return '未知类型';
        }
    };

    const handleAnswer = (questionId: number, answer: string) => {
        setAnswers({...answers, [questionId]: answer});
    };

    const handleMultipleChoiceAnswer = (questionId: number, option: string) => {
        const currentAnswer = answers[questionId] || '';
        let newAnswer: string;
        if (currentAnswer.includes(option)) {
            newAnswer = currentAnswer.replace(option, '').replace(/,,/g, ',').replace(/(^,|,$)/g, '');
        } else {
            newAnswer = currentAnswer ? `${currentAnswer},${option}` : option;
        }
        // 对答案进行排序
        newAnswer = newAnswer.split(',').sort().join(',');
        setAnswers({...answers, [questionId]: newAnswer});
    };


    const handleNext = () => {
        if (currentQuestionIndex < questions.length - 1) {
            setCurrentQuestionIndex(currentQuestionIndex + 1);
        }
    };

    const handlePrevious = () => {
        if (currentQuestionIndex > 0) {
            setCurrentQuestionIndex(currentQuestionIndex - 1);
        }
    };

    const handleSubmit = async () => {
        if (Object.keys(answers).length !== questions.length) {
            setSnackbarMessage('请答完所有题目再提交');
            setSnackbarSeverity('warning');
            setSnackbarOpen(true);
            return;
        }
        try {
            const submissionAnswers = Object.entries(answers).map(([questionId, answer]) => ({
                question_id: parseInt(questionId),
                answer: answer
            }));

            const response = await api.post('/questions/submit', { answers: submissionAnswers });
            setSnackbarMessage('提交成功');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);

            if (response.data.wrong_answers && response.data.wrong_answers.length > 0) {
                setWrongAnswers(response.data.wrong_answers);
                setShowWrongAnswers(true);
            } else {
                setAllCorrect(true); // 全答对
            }
        } catch (error) {
            console.error('提交答案失败:', error);
            setSnackbarMessage('提交答案失败');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };


    const handleJumpToQuestion = (index: number) => {
        setCurrentQuestionIndex(index);
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    const handleCloseWrongAnswers = () => {
        setShowWrongAnswers(false);
        navigate('/quiz-selection');
    };

    const renderQuestionOptions = (question: Question, isReview: boolean = false) => {
        const options = ['A', 'B', 'C', 'D'];
        switch (question.type) {
            case 'multiple_choice':
                return options.map(option => {
                    const optionValue = question[`option_${option.toLowerCase()}` as keyof Question];
                    if (optionValue) {
                        return (
                            <FormControlLabel
                                key={option}
                                control={
                                    <Checkbox
                                        checked={answers[question.id]?.includes(option) || false}
                                        onChange={() => handleMultipleChoiceAnswer(question.id, option)}
                                        disabled={isReview}
                                    />
                                }
                                label={`${option}. ${optionValue}`}
                            />
                        );
                    }
                    return null;
                });
            case 'single_choice':
            case 'true_false':
                return (
                    <RadioGroup
                        value={answers[question.id] || ''}
                        onChange={(e) => handleAnswer(question.id, e.target.value)}
                    >
                        {options.map(option => {
                            const optionValue = question[`option_${option.toLowerCase()}` as keyof Question];
                            if (optionValue) {
                                return (
                                    <FormControlLabel
                                        key={option}
                                        value={option}
                                        control={<Radio disabled={isReview} />}
                                        label={`${option}. ${optionValue}`}
                                    />
                                );
                            }
                            return null;
                        })}
                    </RadioGroup>
                );
            default:
                return null;
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography color="error">{error}</Typography>
            </Box>
        );
    }

    if (questions.length === 0) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                <Typography>没有可用的题目</Typography>
            </Box>
        );
    }

    const currentQuestion = questions[currentQuestionIndex];

    return (
        <Container maxWidth="lg">
            <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                    <Paper elevation={3} sx={{ p: 3, mt: 4 }}>
                        <Box display="flex" alignItems="center" justifyContent="space-between" mb={2}>
                            <Typography variant="h5">
                                问题 {currentQuestionIndex + 1} / {questions.length}
                            </Typography>
                            <Chip
                                label={getQuestionTypeLabel(currentQuestion.type)}
                                color="primary"
                                size="small"
                            />
                        </Box>
                        <Typography variant="body1" gutterBottom>
                            {currentQuestion.content}
                        </Typography>
                        {renderQuestionOptions(currentQuestion)}
                        <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                            <Button onClick={handlePrevious} disabled={currentQuestionIndex === 0}>
                                上一题
                            </Button>
                            {currentQuestionIndex === questions.length - 1 ? (
                                <Button onClick={handleSubmit} variant="contained" color="primary">
                                    提交
                                </Button>
                            ) : (
                                <Button onClick={handleNext} variant="contained" color="primary">
                                    下一题
                                </Button>
                            )}
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AnswerCard
                        questions={questions}
                        answers={answers}
                        currentQuestionIndex={currentQuestionIndex}
                        onJumpToQuestion={handleJumpToQuestion}
                    />
                </Grid>
            </Grid>

            <Dialog open={showWrongAnswers} onClose={handleCloseWrongAnswers} maxWidth="md" fullWidth>
                <DialogTitle>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                        <Typography variant="h6">错题回顾</Typography>
                        <Typography variant="subtitle1">
                            共 {wrongAnswers.length} 道错题
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <List>
                        {wrongAnswers.map((question, index) => (
                            <ListItem key={question.id} divider>
                                <ListItemText
                                    primary={
                                        <Box display="flex" alignItems="center" mb={1}>
                                            <Typography variant="subtitle1" style={{ marginRight: 8 }}>
                                                {index + 1}. {question.content}
                                            </Typography>
                                            <Chip
                                                label={getQuestionTypeLabel(question.type)}
                                                color="primary"
                                                size="small"
                                            />
                                        </Box>
                                    }
                                    secondary={
                                        <Box>
                                            {renderQuestionOptions(question, true)}
                                            <Typography component="span" variant="body2" color="textPrimary">
                                                正确答案: {question.answer}
                                            </Typography>
                                            <Typography component="span" variant="body2" color="error" style={{ marginTop: 8, display: 'block' }}>
                                                您的答案: {answers[question.id]}
                                            </Typography>
                                        </Box>
                                    }
                                />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseWrongAnswers} color="primary">
                        关闭并返回主页
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={allCorrect}
                onClose={() => { setAllCorrect(false); navigate('/quiz-selection'); }}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>
                    <Typography variant="h6" align="center">恭喜！全答对了！</Typography>
                </DialogTitle>
                <DialogContent>
                    <Box display="flex" flexDirection="column" alignItems="center" py={2}>
                        <Box width="100%" height="100%" position="relative">
                        <Typography variant="body1" align="center" gutterBottom>
                            你真棒！所有题目都答对了！
                        </Typography>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => { setAllCorrect(false); navigate('/quiz-selection'); }}
                        color="primary"
                        fullWidth
                    >
                        关闭并返回主页
                    </Button>
                </DialogActions>
            </Dialog>

            {allCorrect && (
                <Confetti
                    recycle={false}
                    numberOfPieces={200}
                    tweenDuration={5000}
                    style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        zIndex: 9999,
                        pointerEvents: 'none'
                    }}
                />
            )}

            <Snackbar
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default Quiz;
