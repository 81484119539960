import axios from 'axios';

// 根据环境确定基础URL
const getBaseUrl = () => {
    if (process.env.NODE_ENV === 'production') {
        // 在生产环境中，使用相对路径
        return '/api';
    } else {
        // 在开发环境中，使用完整的URL
        return 'http://localhost:2377/api';
    }
};

const api = axios.create({
    baseURL: getBaseUrl(),
});

api.interceptors.request.use(
    (config) => {
        const token = sessionStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        console.error('请求拦截器错误:', error);
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => response,
    (error) => {
        console.error('响应拦截器错误:', error.response?.data || error.message);
        if (error.response && error.response.status === 401) {
            sessionStorage.removeItem('token');
            window.dispatchEvent(new Event('unauthorizedAccess'));
        }
        return Promise.reject(error);
    }
);

export default api;
