import React, { useState } from 'react';
import { Box, Button, Typography, Collapse, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

interface AnswerCardProps {
    questions: any[];
    answers: {[key: number]: string};
    currentQuestionIndex: number;
    onJumpToQuestion: (index: number) => void;
}

const AnswerCard: React.FC<AnswerCardProps> = ({ questions, answers, currentQuestionIndex, onJumpToQuestion }) => {
    const [expanded, setExpanded] = useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box sx={{ mt: 4, p: 2, border: '1px solid #ccc', borderRadius: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                <Typography variant="h6">
                    答题卡
                </Typography>
                <IconButton onClick={handleExpandClick}>
                    {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
            </Box>
            <Collapse in={expanded}>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {questions.map((question, index) => (
                        <Button
                            key={question.id}
                            variant={index === currentQuestionIndex ? 'contained' : 'outlined'}
                            color={answers[question.id] ? 'primary' : 'secondary'}
                            onClick={() => onJumpToQuestion(index)}
                            sx={{ minWidth: 40 }}
                        >
                            {index + 1}
                        </Button>
                    ))}
                </Box>
            </Collapse>
        </Box>
    );
};

export default AnswerCard;
