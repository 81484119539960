import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, List, ListItem, ListItemText, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar } from '@mui/material';
import api from '../utils/api';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

interface User {
    id: number;
    username: string;
    isAdmin: boolean;
}

const UserManagement: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);
    const [newUsername, setNewUsername] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [openDialog, setOpenDialog] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const { user } = useAuth();
    const navigate = useNavigate();

    useEffect(() => {
        if (!user?.isAdmin) {
            navigate('/quiz-selection');
            return;
        }

        const fetchUsers = async () => {
            try {
                const response = await api.get('/admin/users', {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
                });
                setUsers(response.data);
            } catch (error) {
                console.error('获取用户列表失败:', error);
                setSnackbarMessage('获取用户列表失败');
            }
        };
        fetchUsers();
    }, [user, navigate]);

    const handleCreateUser = async () => {
        try {
            await api.post('/admin/users', {
                username: newUsername,
                password: newPassword
            }, {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setOpenDialog(false);
            setNewUsername('');
            setNewPassword('');
            setSnackbarMessage('用户创建成功');
            // 刷新用户列表
            const response = await api.get('/admin/users', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
            });
            setUsers(response.data);
        } catch (error) {
            console.error('创建用户失败:', error);
            setSnackbarMessage('创建用户失败');
        }
    };

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setNewUsername('');
        setNewPassword('');
    };

    return (
        <Container maxWidth="md">
            <Box sx={{ mt: 4 }}>
                <Typography variant="h4" gutterBottom>
                    用户管理
                </Typography>
                <Button variant="contained" color="primary" onClick={handleOpenDialog} sx={{ mb: 2 }}>
                    创建新用户
                </Button>
                <List>
                    {users.map((user) => (
                        <ListItem key={user.id}>
                            <ListItemText
                                primary={user.username}
                                secondary={user.isAdmin ? '管理员' : '普通用户'}
                            />
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>创建新用户</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="username"
                        label="用户名"
                        type="text"
                        fullWidth
                        value={newUsername}
                        onChange={(e) => setNewUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="password"
                        label="密码"
                        type="password"
                        fullWidth
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog}>取消</Button>
                    <Button onClick={handleCreateUser}>创建</Button>
                </DialogActions>
            </Dialog>
            <Snackbar
                open={!!snackbarMessage}
                autoHideDuration={6000}
                onClose={() => setSnackbarMessage('')}
                message={snackbarMessage}
            />
        </Container>
    );
};

export default UserManagement;
