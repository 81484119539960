import React, {createContext, useState, useContext, useEffect, useCallback} from 'react';
import api from '../utils/api';

interface User {
    id: number;
    username: string;
    isAdmin: boolean;
}

interface AuthContextType {
    user: User | null;
    login: (username: string, password: string) => Promise<void>;
    logout: () => void;
    checkAuth: () => Promise<boolean>;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<React.PropsWithChildren<{}>> = ({ children }) => {
    const [user, setUser] = useState<User | null>(null);

    const checkAuth = useCallback(async () => {
        const token = sessionStorage.getItem('token');
        if (token) {
            try {
                api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
                const response = await api.get('/auth/me');
                const userData = response.data;
                setUser({ id: userData.id, username: userData.username, isAdmin: userData.isAdmin });
                return true;
            } catch (error) {
                console.error('验证失败:', error);
                sessionStorage.removeItem('token');
                delete api.defaults.headers.common['Authorization'];
                setUser(null);
                return false;
            }
        }
        return false;
    }, []);

    useEffect(() => {
        if (!user) {
            checkAuth().then(r => {
                if (!r) {
                    console.error('未登录');
                }
            });
        }
    }, [user, checkAuth]);
    const login = async (username: string, password: string) => {
        try {
            const response = await api.post('/auth/login', { username, password });
            const { token, user: userData } = response.data;
            sessionStorage.setItem('token', token);
            api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            setUser({ id: userData.id, username: userData.username, isAdmin: userData.isAdmin });
        } catch (error) {
            console.error('登录失败:', error);
            throw error;
        }
    };

    const logout = () => {
        sessionStorage.removeItem('token');
        delete api.defaults.headers.common['Authorization'];
        setUser(null);
    };

    return (
        <AuthContext.Provider value={{ user, login, logout, checkAuth }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth 必须在 AuthProvider 内部使用');
    }
    return context;
};
