import React from 'react';
import { ThemeProvider as MuiThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './components/Header';
import Login from './components/Login';
import QuizSelection from './components/QuizSelection';
import Quiz from './components/Quiz';
import UserManagement from './components/UserManagement';
import PrivateRoute from './components/PrivateRoute';
import { AuthProvider } from './contexts/AuthContext';
import { ThemeProvider, useTheme } from './contexts/ThemeContext';

const ThemedApp: React.FC = () => {
  const { isDarkMode } = useTheme();

  const theme = createTheme({
    palette: {
      mode: isDarkMode ? 'dark' : 'light',
      primary: {
        main: '#1976d2',
      },
      secondary: {
        main: '#dc004e',
      },
    },
  });

  return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <Router>
            <Header />
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/quiz-selection" element={
                <PrivateRoute>
                  <QuizSelection />
                </PrivateRoute>
              } />
              <Route path="/quiz" element={
                <PrivateRoute>
                  <Quiz />
                </PrivateRoute>
              } />
              <Route path="/user-management" element={
                <PrivateRoute>
                  <UserManagement />
                </PrivateRoute>
              } />
              <Route path="/" element={
                <PrivateRoute>
                  <QuizSelection />
                </PrivateRoute>
              } />
            </Routes>
          </Router>
        </AuthProvider>
      </MuiThemeProvider>
  );
};

function App() {
  return (
      <ThemeProvider>
        <ThemedApp />
      </ThemeProvider>
  );
}

export default App;
