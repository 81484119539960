import React from 'react';
import { useTheme } from '../contexts/ThemeContext';
import { IconButton, Menu, MenuItem } from '@mui/material';
import { Brightness4, Brightness7 } from '@mui/icons-material';

const ThemeToggle: React.FC = () => {
    const {setMode, isDarkMode } = useTheme();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleModeChange = (newMode: 'light' | 'dark' | 'system') => {
        setMode(newMode);
        handleClose();
    };

    return (
        <>
            <IconButton color="inherit" onClick={handleClick}>
                {isDarkMode ? <Brightness7 /> : <Brightness4 />}
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                <MenuItem onClick={() => handleModeChange('light')}>
                    亮色模式
                </MenuItem>
                <MenuItem onClick={() => handleModeChange('dark')}>
                    暗色模式
                </MenuItem>
                <MenuItem onClick={() => handleModeChange('system')}>
                    跟随系统
                </MenuItem>
            </Menu>
        </>
    );
};

export default ThemeToggle;
