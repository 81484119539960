import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { ExitToApp } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, Link } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';

const Header: React.FC = () => {
    const { user, logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate('/login');
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" style={{ flexGrow: 1 }}>
                    在线测验
                </Typography>
                <ThemeToggle />
                {user && (
                    <>
                        <Typography variant="body1" style={{ marginRight: 16 }}>
                            欢迎，{user.username}
                        </Typography>
                        {user.isAdmin && (
                            <Button color="inherit" component={Link} to="/user-management">
                                用户管理
                            </Button>
                        )}
                        <Button color="inherit" onClick={handleLogout} startIcon={<ExitToApp />}>
                            退出
                        </Button>
                    </>
                )}
            </Toolbar>
        </AppBar>
    );
};

export default Header;
